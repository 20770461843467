import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
// import Vuex from "vuex";
import VueMeta from "vue-meta";
// import { store } from "./store";

import "./assets/css/normalize.css";
import "./assets/css/styles.css";

// Vue.use(Vuex);
Vue.use(VueMeta);

Vue.config.productionTip = false;

new Vue({
  // store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
