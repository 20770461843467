<template>
  <div>
    <header-main></header-main>
    <building-site></building-site>
    <!-- <hero-1></hero-1> -->

    <section id="servicios" class="services-hero">
      <div class="services__box">
        <h1 class="services-h1">Servicios a tu medida</h1>
        <p class="services-p">
          Somos una propuesta empresarial a la medida de los nuevos tiempos,
          tiempos difíciles y que requieren de la adaptación a la era digital.
        </p>
        <div class="services-services">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <div class="services-item">
                  <img
                    src="statics/img/services/sites.png"
                    alt="Logo de servicio de desarrollo de sitios web"
                  />
                  <h3>
                    Landing Page <br />
                    &nbsp;
                  </h3>
                  <p>
                    Conocida como página de aterrizaje, es creada con el único
                    objetivo de convertir los visitantes en prospectos de ventas
                    por medio de una oferta determinada.
                  </p>
                  <router-link style="text-decoration: none" to="/#">
                    <v-btn
                      style="background: var(--primary); color: white"
                      elevation="1"
                      dark
                    >
                      Ver planes
                    </v-btn>
                  </router-link>
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <div class="services-item">
                  <img
                    src="statics/img/services/sites.png"
                    alt="Logo de servicio de desarrollo de sitios web"
                  />
                  <h3>
                    Páginas web <br />
                    &nbsp;
                  </h3>
                  <p>
                    Potencializa tu marca personal o empresarial, dándole
                    prestigio y reconocimiento. Es una ventana para que en
                    internet descubran tus servicios.
                  </p>
                  <router-link style="text-decoration: none" to="/#">
                    <v-btn
                      style="background: var(--primary); color: white"
                      elevation="1"
                      dark
                    >
                      Ver planes
                    </v-btn>
                  </router-link>
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <div class="services-item">
                  <img
                    src="statics/img/services/dev.png"
                    alt="Logo de servicio de desarrollo a la medida"
                  />

                  <h3>
                    Desarrollo de software <br />
                    &nbsp;
                  </h3>
                  <p>
                    En base a una idea o un proyecto ya estructurado, nos
                    acoplamos para ejecutar cada una de sus etapas. Construimos
                    tu software a la medida.
                  </p>
                  <router-link style="text-decoration: none" to="/#">
                    <v-btn
                      style="background: var(--primary); color: white"
                      elevation="1"
                      dark
                    >
                      Ver planes
                    </v-btn>
                  </router-link>
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <div class="services-item">
                  <img
                    src="statics/img/services/services.png"
                    alt="Logo de servicio de Hosting"
                  />
                  <h3>Hosting (Alojamiento para sitios web)</h3>
                  <p>
                    Aquí se alojan los contenidos (imágenes, fotos, videos,
                    documentos y archivos) de tu sitio web para que puedan ser
                    visitados desde cualquier lugar del mundo.
                  </p>
                  <router-link style="text-decoration: none" to="/#">
                    <v-btn
                      style="background: var(--primary); color: white"
                      elevation="1"
                      dark
                    >
                      Ver planes
                    </v-btn>
                  </router-link>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div class="services-item">
                  <img
                    src="statics/img/services/services.png"
                    alt="Logo de servicio de Hosting"
                  />
                  <h3>
                    Dominios <br />
                    &nbsp;
                  </h3>
                  <p>
                    Exactamente es tu dirección en internet, ejemplo:
                    casasenventa.com. Por medio de este tus clientes pueden
                    encontrarte y descubrir tus servicios.
                  </p>
                  <router-link style="text-decoration: none" to="/#">
                    <v-btn
                      style="background: var(--primary); color: white"
                      elevation="1"
                      dark
                    >
                      Ver planes
                    </v-btn>
                  </router-link>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div class="services-item">
                  <img
                    src="statics/img/services/ss.png"
                    alt="Logo de servicio de SEO (Search Engine Optimization)"
                  />

                  <h3>
                    Posicionamiento <br />
                    web
                  </h3>
                  <p>
                    Posicionamos tu sitio web para un segmento de mercado
                    especifico a través de técnicas y herramientas que llevaran
                    a que tengas más exposición para tu marca.
                  </p>
                  <router-link style="text-decoration: none" to="/#">
                    <v-btn
                      style="background: var(--primary); color: white"
                      elevation="1"
                      dark
                    >
                      Ver información
                    </v-btn>
                  </router-link>
                  <!-- <v-dialog v-model="dialog_seo" max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="background: var(--primary); color: white"
                        elevation="1"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        Ver Información
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5">
                        SEO (Search Engine Optimization)
                      </v-card-title>
                      <v-card-text>
                        Todos nuestros servicios cuentan con soporte y garantía,
                        para ver más información sobre soporte y garantías clic
                        <a href="#">aquí</a>.
                      </v-card-text>
                      <v-card-text>
                        <v-alert
                          outlined
                          icon="mdi-head-question-outline "
                          color="purple"
                          prominent
                          border="left"
                        >
                          <div class="text-h6">¿Tienes dudas?</div>
                          Contactanos por WhatsApp o Agenda una reunión.
                        </v-alert>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="dark darken-1"
                          text
                          @click="dialog_seo = false"
                        >
                          Cerrar
                        </v-btn>
                        <v-btn
                          color="purple darken-1"
                          text
                          @click="dialog_seo = false"
                        >
                          Agendar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog> -->
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div class="services-item">
                  <img
                    src="statics/img/services/social.png"
                    alt="Logo de publicidad en Facebook e Instagram"
                  />

                  <h3>Publicidad en redes sociales</h3>
                  <p>
                    Ajustamos campañas publicitarias a tu medida, te asesoramos
                    y te damos las opciones que más te convienen. Usamos
                    nuestras herramientas a tu favor.
                  </p>
                  <router-link style="text-decoration: none" to="/#">
                    <v-btn
                      style="background: var(--primary); color: white"
                      elevation="1"
                      dark
                    >
                      Ver información
                    </v-btn>
                  </router-link>
                  <!-- <v-dialog v-model="dialog_fi" max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="background: var(--primary); color: white"
                        elevation="1"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        Ver Información
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5">
                        Facebook e Instagram Ads
                      </v-card-title>
                      <v-card-text>
                        Todos nuestros servicios cuentan con soporte y garantía,
                        para ver más información sobre soporte y garantías clic
                        <a href="#">aquí</a>.
                      </v-card-text>
                      <v-card-text>
                        <v-alert
                          outlined
                          icon="mdi-head-question-outline "
                          color="purple"
                          prominent
                          border="left"
                        >
                          <div class="text-h6">¿Tienes dudas?</div>
                          Contactanos por WhatsApp o Agenda una reunión.
                        </v-alert>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="dark darken-1"
                          text
                          @click="dialog_fi = false"
                        >
                          Cerrar
                        </v-btn>
                        <v-btn
                          color="purple darken-1"
                          text
                          @click="dialog_fi = false"
                        >
                          Agendar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog> -->
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div class="services-item">
                  <img
                    src="statics/img/services/support.png"
                    alt="Logo de Soporte y Garantía"
                  />
                  <h3>
                    Soporte y Garantía <br />
                    &nbsp;
                  </h3>
                  <p>
                    Cada uno de nuestros servicios tiene sus propios términos de
                    soporte y garantía, nosotros te brindamos toda la
                    información sobre ello.
                  </p>
                  <router-link style="text-decoration: none" to="/#">
                    <v-btn
                      style="background: var(--primary); color: white"
                      elevation="1"
                      dark
                    >
                      Ver información
                    </v-btn>
                  </router-link>
                  <!-- <v-dialog v-model="dialog" max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="background: var(--primary); color: white"
                        elevation="1"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        Ver Información
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5">
                        Soporte y Garantía
                      </v-card-title>
                      <v-card-text>
                        Todos nuestros servicios cuentan con soporte y garantía,
                        para ver más información sobre soporte y garantías clic
                        <a href="#">aquí</a>.
                      </v-card-text>
                      <v-card-text>
                        <v-alert
                          outlined
                          icon="mdi-head-question-outline "
                          color="purple"
                          prominent
                          border="left"
                        >
                          <div class="text-h6">¿Tienes dudas?</div>
                          Contactanos por WhatsApp o Agenda una reunión.
                        </v-alert>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="dark darken-1"
                          text
                          @click="dialog = false"
                        >
                          Cerrar
                        </v-btn>
                        <v-btn
                          color="purple darken-1"
                          text
                          @click="dialog = false"
                        >
                          Agendar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog> -->
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </section>

    <steps></steps>
    <!-- <features></features> -->
    <scheduling background="white-secondary"></scheduling>
    <team></team>
    <portfolio id="proyectos"></portfolio>
    <hero></hero>
    <our-clients></our-clients>
    <!-- <location></location> -->
    <footer-main></footer-main>
  </div>
</template>

<script>
import logo from "../assets/logo.svg";
import HeaderMain from "@/components/Header.vue";
import Hero from "@/components/Hero.vue";
// import Hero1 from "@/components/Hero1.vue";
// import Features from "@/components/Features.vue";
import Steps from "@/components/Steps.vue";
import BuildingSite from "@/components/BuildingSite.vue";
import Scheduling from "@/components/Scheduling.vue";
import Team from "@/components/Team.vue";
import Portfolio from "@/components/Portfolio.vue";
import OurClients from "@/components/OurClients.vue";
// import Location from "@/components/Location.vue";
import FooterMain from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    HeaderMain,
    // Hero1,
    // Features,
    Steps,
    BuildingSite,
    Scheduling,
    Team,
    Portfolio,
    Hero,
    OurClients,
    // Location,
    FooterMain,
  },
  data: () => ({
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
    importantLinks: [
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com",
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuejs.com/vuetify",
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs",
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify",
      },
    ],
    logo,
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com",
      },
      {
        text: "Roadmap",
        href: "https://vuetifyjs.com/introduction/roadmap/",
      },
      {
        text: "Frequently Asked Questions",
        href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
      },
    ],
  }),
  metaInfo() {
    return {
      title: "Appears |",
      titleTemplate:
        "%s Agencia Digital | Estamos reestructurando nuestro sitio web.",
      meta: [
        {
          name: "description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "keywords",
          content:
            "Appears, paginas web, paginas web villavicencio, sitio web, seo, sem, aplicaciones a la medida, software, google ads, facebook ads, publicidad en google, publicidad en facebook, publicidad en instagram, web, website, diseño web, desarrollo web",
        },
        {
          name: "author",
          content: "Appears",
        },
        {
          name: "robots",
          content: "Index, Follow",
        },
        {
          rel: "canonical",
          href: "https://appears.com.co/",
        },
        // Tags twitter
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:site",
          content: "@appears",
        },
        {
          name: "twitter:creator",
          content: "@appears",
        },
        {
          name: "twitter:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          name: "twitter:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "twitter:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        // Tags facebook
        {
          property: "og:locale",
          content: "es_CO",
        },
        {
          property: "og:site_name",
          content: "Appears",
        },
        {
          property: "og:url",
          content: "https://appears.com.co",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          property: "og:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          property: "og:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:secure_url",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:width",
          content: "705",
        },
        {
          property: "og:image:height",
          content: "427",
        },
      ],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "Appears",
            url: "https://www.appears.com.co",
            sameAs: [
              "https://twitter.com/appearssas",
              "https://www.facebook.com/appearssas",
              "https://www.instagram.com/appearssas",
              "https://www.linkedin.com/company/appearssas",
              "https://www.youtube.com/channel/UClUvTE5_kt9_0HDfhhIwnNA",
            ],
          },
        },
      ],
    };
  },
};
</script>
<style scoped>
.services-hero {
  /* background: var(--white-secondary); */
  /* display: grid; */
  background-image: url(/statics/img/shapes/shape-back4.svg);
  background-size: cover;
}
.services__box {
  margin: auto;
  max-width: 1200px;

  padding: 80px 80px 100px 80px;
}
.services {
  text-align: left;
  /* background: blue; */
}
.services-h1 {
  font-family: "Poppins";
  color: var(--primary-opt);
  font-size: var(--h1);
  /* font-weight: 800; */
  line-height: 0.2;
  margin-bottom: 0;
}
.services-h2 {
  margin-top: 50px;
  color: var(--black);
  font-size: 30px;
  line-height: 1;
}
.services-h2 span {
  color: var(--positive);
  font-size: 35px;
  font-weight: 800;
  line-height: 1;
}
.services-p {
  /* font-family: "Poppins", sans-serif; */
  font-size: 17px;
  margin-top: 20px;
  margin-right: 20px;
  color: var(--black);
  font-weight: normal;
}
.services-services img {
  height: 70px;
  margin-right: 5px;
  border: 2.5px solid var(--primary);
  border-radius: 15px;
}
.services-item {
  text-align: center;
  background: var(--secondary);
  min-height: 200px;
  border-radius: 15px;
  -webkit-box-shadow: 1px 5px 8px -2px #ab67d1;
  -moz-box-shadow: 1px 5px 8px -2px #ab67d1;
  box-shadow: 1px 5px 8px -2px #ab67d1;
  padding: 20px 10px;
  transition: transform 0.2s; /* Animation */
}
.services-item:hover {
  transform: scale(1.1);
  border: 2px solid var(--primary);
}
.services-item h3 {
  font-size: 17px;
}

.services-tooltip {
  background: var(--primary);
}
.services-main {
  position: relative;
  z-index: 1;
  /* height: 500px; */
  background: var(--white-secondary);
}
.services__back {
  background-image: url(/statics/img/shapes/steps-back.png);
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 800px;
  background-repeat: no-repeat;
  background-size: contain;
}
.dots {
  background-image: url(/statics/img/shapes/dots.png);
  position: absolute;
  top: 25px;
  left: -225px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 991px) {
  .services {
    width: 100%;
    height: 900px;
    margin-bottom: 50px;
  }
  .services__box {
    grid-template-columns: 100%;
    padding: 30px 30px 60px 30px;
  }
}
@media screen and (max-width: 480px) {
  .services-h1 {
    font-size: 35px;
    line-height: 0.8;
  }
  .services-h2,
  .services-h2 span {
    font-size: 22px;
  }
  .services__back {
    background-image: url(/statics/img/shapes/steps-back-m.png);
  }
}
</style>
