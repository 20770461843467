<template>
  <section class="projects">
    <div class="projects__container">
      <h2 class="projects__h2">
        Trabajamos cada día para ser aliados estratégicos de nuestros clientes
      </h2>
      <v-tabs background-color="transparent" v-model="tab" centered>
        <v-tab class="projects__tab" href="#sites">Sitios Web</v-tab>
        <v-tab class="projects__tab" href="#software">Software</v-tab>
        <v-tab class="projects__tab" href="#desing">Diseño UI</v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item
            style="background: transparent"
            background-color="transparent"
            value="sites"
            :transition="false"
          >
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-myfip.jpg"
                        alt="Captura hero de sitio web Myfip"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">
                            Myfip | Mis finanzas personales
                          </p>
                        </div>
                        <div class="portfolio__link-site">
                          <a
                            class="portfolio__link"
                            href="https://projects.appears.com.co/myfip/"
                            target="_black"
                          >
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-cengeocol.png"
                        alt="Captura hero de sitio web Cengeocol"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">Cengeocol</p>
                        </div>
                        <div class="portfolio__link-site">
                          <a
                            class="portfolio__link"
                            href="https://projects.appears.com.co/cengeocol/"
                            target="_black"
                          >
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-afiliarse.jpg"
                        alt="Captura hero de sitio web Afiliarse a Yanbal"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">
                            Afiliarse a Yanbal | Camila Medina
                          </p>
                        </div>
                        <div class="portfolio__link-site">
                          <a class="portfolio__link" href="/#proyectos">
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-natura.png"
                        alt="Captura hero de sitio web Hotel Natura"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">Hotel Natura</p>
                        </div>
                        <div class="portfolio__link-site">
                          <a
                            class="portfolio__link"
                            href="https://hotelnatura.co/"
                            target="_black"
                          >
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-afiliacion.jpg"
                        alt="Captura hero de sitio web Afiliación a Yanbal"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">
                            Afiliacíon a Yanbal | Rosa Mendoza
                          </p>
                        </div>
                        <div class="portfolio__link-site">
                          <a
                            class="portfolio__link"
                            href="https://projects.appears.com.co/afiliacion-a-yanbal/"
                            target="_black"
                          >
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-hyr.png"
                        alt="Captura hero de sitio web Hotel H&R"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">Hotel H&R</p>
                        </div>
                        <div class="portfolio__link-site">
                          <a
                            class="portfolio__link"
                            href="https://hotelhyr.com/"
                            target="_black"
                          >
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-qinspecting-site.png"
                        alt="Captura hero de sitio web Qinspecting"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">Qinspecting | Sitio web</p>
                        </div>
                        <div class="portfolio__link-site">
                          <a
                            class="portfolio__link"
                            href="https://qinspecting.com"
                            target="_black"
                          >
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-cryptoinversiones.png"
                        alt="Captura hero de sitio web CryptoInversiones"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">CryptoInversiones</p>
                        </div>
                        <div class="portfolio__link-site">
                          <a
                            class="portfolio__link"
                            href="https://cryptoinversiones.co"
                            target="_black"
                          >
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item
            style="background: transparent"
            background-color="transparent"
            value="software"
            :transition="false"
          >
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-qinspecting.png"
                        alt="Captura hero de sitio web Qinspecting | Gestión de flotas"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">
                            Qinspecting | Gestión de flotas
                          </p>
                        </div>
                        <div class="portfolio__link-site">
                          <a
                            class="portfolio__link"
                            href="https://newapp.qinspecting.com/"
                            target="_black"
                          >
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-cr-distri.png"
                        alt="Captura hero de software CR Distribuidora"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">
                            CR Distribuidora | Gestión de inventario
                          </p>
                        </div>
                        <div class="portfolio__link-site">
                          <a
                            class="portfolio__link"
                            href="https://crdistribuidora.com/"
                            target="_black"
                          >
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-mi-entretenimiento.png"
                        alt="Captura hero de sitio web Mi Entretenimiento"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">Mundo Digital</p>
                        </div>
                        <div class="portfolio__link-site">
                          <a
                            class="portfolio__link"
                            href="https://mundodigital.online/"
                            target="_black"
                          >
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-cr.png"
                        alt="Captura hero de sitio web Sigi | Gestión de inventario"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">
                            Sigi | Gestión de inventario
                          </p>
                        </div>
                        <div class="portfolio__link-site">
                          <a class="portfolio__link" href="#" target="_black">
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-gib.jpg"
                        alt="Captura hero de sitio web Gibmafe"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">
                            Gibmafe | Gestión de inventario
                          </p>
                        </div>
                        <div class="portfolio__link-site">
                          <a class="portfolio__link" href="#" target="_black">
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-motor-reservas.png"
                        alt="Captura hero de sitio web Moti | Motor de reservas"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">Moti | Motor de reservas</p>
                        </div>
                        <div class="portfolio__link-site">
                          <a class="portfolio__link" href="#" target="_black">
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item
            style="background: transparent"
            background-color="transparent"
            value="desing"
            :transition="false"
          >
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-siges.png"
                        alt="Captura hero de diseño sitio web Prefabricados J.A.V"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">
                            Siges | Gestions de documentos
                          </p>
                        </div>
                        <div class="portfolio__link-site">
                          <a
                            class="portfolio__link"
                            href="https://xd.adobe.com/view/45b8dce8-ee28-4221-a02f-7428dd210c6d-10eb/?fullscreen"
                            target="_black"
                          >
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-jav.jpg"
                        alt="Captura hero de diseño sitio web Prefabricados J.A.V"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">Prefabricados J.A.V</p>
                        </div>
                        <div class="portfolio__link-site">
                          <a class="portfolio__link" href="#" target="_black">
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <div class="portfolio__wrap-tarj">
                    <div class="portfolio__tarj">
                      <img
                        src="../../public/statics/img/projects/tarj-des-myfip.png"
                        alt="Captura hero de diseño App Myfip"
                      />
                      <div class="portfolio__item-footer">
                        <div class="portfolio__description-site">
                          <p class="portfolio__p">Myfip</p>
                        </div>
                        <div class="portfolio__link-site">
                          <a class="portfolio__link" href="#" target="_black">
                            <v-icon large color="purple "> mdi-link </v-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </section>
</template>

<script>
export default {
  name: "Projects",
  title: "Desarrollamos tu sitio web en 5 pasos.",
  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style scoped>
.v-window.v-item-group.theme--light.v-tabs-items {
  background-color: transparent !important;
}
.projects {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  min-height: 700px;
  /* background: var(--white); */
  background-image: url(/statics/img/shapes/shape-back2.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
/* .projects img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
} */
.projects__h2 {
  margin: auto;
  max-width: 900px;
  font-size: 35px;
  font-weight: 900;
  color: var(--white);
  text-align: center;
  text-shadow: 5px 1px 1px rgba(44, 43, 43, 0.6);
}
.projects__container {
  margin: auto;
  padding: 100px 0;
  max-width: 1200px;
  z-index: 1;
}
.projects__tab {
  text-transform: capitalize;
  color: var(--white) !important;
}

@media screen and (max-width: 1199px) {
  .projects__container {
    width: 100%;
    margin: 0 100px;
  }
}

@media screen and (max-width: 991px) {
  .projects {
    background-position: right;
  }
}
@media screen and (max-width: 767px) {
  .projects {
    height: auto;
    padding: 0;
  }
  .projects__container {
    margin: 0 20px;
  }
}

.portfolio__wrap-tarj {
  background: var(--white-secondary);
  position: relative;
  border-top: 3px solid var(--primary);
  border-right: 3px solid var(--primary);
  border-bottom: 5px solid var(--primary);
  border-left: 5px solid var(--primary);
  border-radius: 10px;
  -webkit-box-shadow: 1px 5px 8px -2px #ab67d1;
  -moz-box-shadow: 1px 5px 8px -2px #ab67d1;
  box-shadow: 1px 5px 8px -2px #ab67d1;
  transition: all 0.2s ease 0s;
  --webkit-transform: translateY(0) translateX(0);
}

.portfolio__wrap-tarj:after,
.portfolio__wrap-tarj:before {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 100%;
  height: 3px;
  /* background: #f1f1f1; */
  --webkit-transform: scaleX(0);
  transition: all 0.2s ease;
}

.portfolio__wrap-tarj:before {
  top: 0;
  left: 0;
  --webkit-transform-origin: 0 0;
}

.portfolio__wrap-tarj:after {
  bottom: 0;
  left: 0;
  --webkit-transform-origin: 100% 0;
}

.portfolio__wrap-tarj:hover {
  box-shadow: 5px 5px 0 #1da1f2;
  --webkit-transform: translateY(-10px) translateX(-10px);
  transition: all 0.2s ease 0.4s;
}

.portfolio__wrap-tarj:hover:after,
.portfolio__wrap-tarj:hover:before {
  --webkit-transform: scaleX(1);
}

.portfolio__item-footer {
  /* height: 45px; */
  display: grid;
  grid-template-columns: 80% 20%;
  /* align-items: center; */
  /* background: red; */
}

.portfolio__description-site {
  padding-left: 10px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  background: var(--primary);
  color: #f1f1f1;
}

.portfolio__p {
  margin-bottom: 0;
  line-height: 1;
}

.portfolio__link-site {
  height: 100%;
  background: #f1f1f1;
  border-radius: 0 0 10px 0;
}

.portfolio__link {
  text-decoration: none;
  height: inherit;
  display: grid;
}

.portfolio__link-icon {
  justify-self: center;
  align-self: center;
  font-size: 20px;
}

.portfolio__tarj img {
  border-radius: 10px 10px 0 0;
  width: 100%;
}

.portfolio__tarj:after,
.portfolio__tarj:before {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 3px;
  height: 100%;
  --webkit-transform: scaleY(0);
  transition: all 0.2s ease 0.2s;
}

.portfolio__tarj:before {
  top: 0;
  left: 0;
  --webkit-transform-origin: 0 100%;
}

.portfolio__tarj:after {
  top: 0;
  right: 0;
  --webkit-transform-origin: 0 0;
}

.portfolio__tarj:hover:after,
.portfolio__tarj:hover:before {
  --webkit-transform: scaleY(1);
}
</style>
