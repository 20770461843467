<template>
  <section style="background: var(--primary)">
    <div class="our-clients">
      <v-container>
        <v-row>
          <h2 class="our-clients__h2">Nuestros Clientes</h2>
        </v-row>
        <v-row>
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide>
              <img
                src="../../public/statics/img/our-clients/transcarga.png"
                alt="Logo de transcarga"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../../public/statics/img/our-clients/gayco.png"
                alt="Logo de Gayco"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../../public/statics/img/our-clients/puertoriente.png"
                alt="Logo de Puertoriente"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../../public/statics/img/our-clients/transtmc.png"
                alt="Logo de Trans TMC"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../../public/statics/img/our-clients/servicusiana.png"
                alt="Logo de Servicusiana"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../../public/statics/img/our-clients/distransllano.png"
                alt="Logo de Distransllano"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../../public/statics/img/our-clients/crdistri.png"
                alt="Logo de CR Distribuidora"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../../public/statics/img/our-clients/portrans.png"
                alt="Logo de Portrans"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../../public/statics/img/our-clients/cengeocol.png"
                alt="Logo de Cengeocol"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../../public/statics/img/our-clients/rianio.png"
                alt="Logo de transportes Riaño"
              />
            </swiper-slide>
          </swiper>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "OurClients",
  title: "Responsive breakpoints",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 10,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          // el: ".swiper-pagination-clients",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 6,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  height: 120px;
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
  }
  .swiper-slide img {
    width: 100%;
    -webkit-box-shadow: 3px 6px 4px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px 6px 4px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 3px 6px 4px -7px rgba(0, 0, 0, 0.75);
  }
}
.our-clients {
  margin: auto;
  max-width: 1200px;
  color: #fafafa;
  padding: 100px 20px 100px 20px;
}
.our-clients__h2 {
  font-size: 45px;
  font-weight: 900;
}
@media screen and (max-width: 410px) {
  .our-clients__h2 {
    font-size: 35px;
  }
}
</style>
