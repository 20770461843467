<template>
  <div>
    <section class="pre-footer text-center">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-img
              class="prefooter__img my-3"
              style="height: 150px"
              src="statics/img/logo-v.png"
              contain
            />
          </v-col>
          <v-col justify="center" class="prefooter-social">
            <a
              class="prefooter-social__link"
              href="https://www.facebook.com/appearssas/"
              target="_blank"
            >
              <v-btn icon class="prefooter__btn-social">
                <v-icon class="prefooter__icon" color="purple"
                  >mdi-facebook</v-icon
                >
              </v-btn>
            </a>
            <a
              class="prefooter-social__link"
              href="https://www.instagram.com/appearssas/"
              target="_blank"
            >
              <v-btn icon class="prefooter__btn-social">
                <v-icon class="prefooter__icon" color="purple"
                  >mdi-instagram</v-icon
                >
              </v-btn>
            </a>
            <a
              class="prefooter-social__link"
              href="https://www.twitter.com/appearssas/"
              target="_blank"
            >
              <v-btn icon class="prefooter__btn-social">
                <v-icon class="prefooter__icon" color="purple"
                  >mdi-twitter</v-icon
                >
              </v-btn>
            </a>
            <a
              class="prefooter-social__link"
              href="https://www.linkedin.com/company/appearssas/"
              target="_blank"
            >
              <v-btn icon class="prefooter__btn-social">
                <v-icon class="prefooter__icon" color="purple"
                  >mdi-linkedin</v-icon
                >
              </v-btn>
            </a>
            <a
              class="prefooter-social__link"
              href="https://www.youtube.com/channel/UClUvTE5_kt9_0HDfhhIwnNA"
              target="_blank"
            >
              <v-btn icon class="prefooter__btn-social">
                <v-icon class="prefooter__icon" color="purple"
                  >mdi-youtube</v-icon
                >
              </v-btn>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="footer">
      <v-container class="footer__box">
        <v-row>
          <v-col cols="12">
            <v-divider class="divider"></v-divider>
          </v-col>
          <!-- <v-col class="mb-5" cols="12" sm="4" md="4">
            <v-row class="footer-links" justify="center">
              <v-img
                class="prefooter__img my-3"
                src="/statics/img/logo-w.png"
                contain
              />
            </v-row>
          </v-col> -->
          <v-col class="mb-5" cols="12" sm="4" md="4">
            <v-row class="footer-links" justify="center">
              <h2 class="headline font-weight-bold mb-5 white--text">
                LINKS IMPORTANTES
              </h2>
              <a
                v-for="(next, i) in importantLinks"
                :key="i"
                :href="next.href"
                class="subheading mb-5"
                target="_blank"
              >
                {{ next.text }}
              </a>
            </v-row>
          </v-col>
          <v-col class="mb-5" cols="12" sm="4" md="4">
            <v-row class="footer-links" justify="center">
              <h2 class="headline font-weight-bold mb-5 white--text">
                LEGAL Y SEGURIDAD
              </h2>
              <a
                v-for="(link, i) in legalAndSecurity"
                :key="i"
                :href="link.href"
                class="subheading mb-5"
                target="_blank"
              >
                {{ link.text }}
              </a>
            </v-row>
          </v-col>
          <v-col class="mb-5" cols="12" sm="4" md="4">
            <v-row class="footer-links" justify="center">
              <h2 class="headline font-weight-bold mb-5 white--text">
                AYUDA Y SOPORTE
              </h2>
              <a
                v-for="(eco, i) in HelpAndSupport"
                :key="i"
                :href="eco.href"
                class="subheading mb-5"
                target="_blank"
              >
                {{ eco.text }}
              </a>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row justify="center">
          <v-divider class="divider"></v-divider>
        </v-row>
        <v-row justify="center">
          <div>
            <span class="copy">
              © 2021 Appears. Todos los derechos reservados.
            </span>
          </div>
        </v-row> -->
      </v-container>
    </section>
    <div class="footer-fo">
      <!-- <div class="footer-fo__icons-social">
        <v-icon class="footer-fo__icon-social" color="purple"
          >mdi-facebook</v-icon
        >
        <v-icon class="footer-fo__icon-social" color="purple"
          >mdi-instagram</v-icon
        >
        <v-icon class="footer-fo__icon-social" color="purple"
          >mdi-linkedin</v-icon
        >
        <v-icon class="footer-fo__icon-social" color="purple"
          >mdi-twitter</v-icon
        >
        <v-icon class="footer-fo__icon-social" color="purple"
          >mdi-youtube</v-icon
        >

      </div> -->
      <span class="footer-fo__span">
        © 2022
        <span class="footer-fo__link scroll-link" href="#hero" data-id="hero"
          >Appears
        </span>
        - Todos los derechos reservados.
      </span>
    </div>

    <!-- <a
      id="app-whatsapp"
      target="_blank"
      href="https://api.whatsapp.com/send?phone=573145792755&amp;text=Hola&nbsp;Cristian!"
    >
      <img src="img/whatsapp.png" alt="Icono de WhatsApp" />
    </a> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    importantLinks: [
      {
        text: "Agendar asesoría",
        href: "https://calendly.com/appearssas/asesoria-personalizada",
      },
      {
        text: "Innovación",
        href: "#",
      },
      {
        text: "Servicios",
        href: "#",
      },
    ],
    legalAndSecurity: [
      {
        text: "Política de Privacidad",
        href: "/#",
      },
      {
        text: "Política de Cookies",
        href: "/#",
      },
      {
        text: "Garatías",
        href: "/#",
      },
    ],
    HelpAndSupport: [
      {
        text: "Contacta a soporte",
        href: "#",
      },
      {
        text: "Soporte de facturación",
        href: "#",
      },
    ],
  }),
};
</script>
<style scoped>
.pre-footer {
  /* background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 13%,
    rgba(232, 224, 255, 1) 97%
  ); */
  background: var(--white-secondary);
}
.prefooter__img {
  height: 150px;
}
.prefooter-social {
  margin-bottom: -50px;
  position: relative;
  bottom: 15px;
}
.prefooter-social__link {
  text-decoration: none;
}
.prefooter__btn-social {
  height: 60px !important;
  width: 60px !important;
  /* margin: 0x; */
  background: transparent;

  box-shadow: none;
}
.prefooter__icon {
  font-size: 35px;
  color: var(--primary) !important;
}
.divider {
  display: flex;
  width: 80%;
  margin: 20px auto;
}
.footer {
  padding: 0 150px 25px 150px;
  background: var(--white-secondary);

  /* background: linear-gradient(180deg, #5b1779 0%, #5b1779 18%, #2f0c3e 65%); */
}
.footer__box {
  max-width: 1300px;
}
.headline {
  font-size: 17px !important;
  color: #000 !important;
}
.subheading {
  text-decoration: none;
  color: #818181;
  font-size: 15 px;
}
.footer-links {
  display: grid;
  text-align: -webkit-left;
}
.copy {
  margin-top: 35px;
  color: #a7a7a7;
}
@media screen and (max-width: 991px) {
  .prefooter__img {
    height: 200px;
  }
  .footer {
    padding: 0 0px 35px 0px;
  }
}
@media screen and (max-width: 365px) {
  .prefooter__btn-social {
    height: 50px !important;
    width: 50px !important;
  }
  .prefooter__icon {
    font-size: 35px;
  }
  .copy {
    font-size: 12px;
  }
}

.footer-fo {
  /* height: 80px; */
  padding: 10px 0;
  display: grid;
  text-align: center;
  align-items: center;
  font-size: 15px;
  /* background: #dcdcdc; */
  background: var(--black);
  color: var(--white);
}

.footer-fo__span {
  font-weight: 400;
}

.footer-fo__link {
  color: var(--white);
  font-size: 17px;
  font-weight: 500;
}

.footer-fo__icon-social {
  color: var(--primary) !important;
  font-size: 20px;
  padding: 0 0.5em;
}
@media screen and (max-width: 360px) {
  .footer-fo__icon-social {
    padding: 0 0.25em;
  }
}
@media screen and (max-width: 320px) {
  .footer-fo__icon-social {
    padding: 0 0.05em;
  }
}
</style>
