<template>
  <div class="building-site">
    <div>
      <main>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="150"
          viewBox="0 0 100 68"
        >
          <g id="large">
            <path
              fill="none"
              stroke="#7500ac"
              d="M55.8 38.5l6.2-1.2c0-1.8-.1-3.5-.4-5.3l-6.3-.2c-.5-2-1.2-4-2.1-6l4.8-4c-.9-1.6-1.9-3-3-4.4l-5.6 3c-1.3-1.6-3-3-4.7-4.1l2-6A30 30 0 0 0 42 8l-3.3 5.4c-2-.7-4.2-1-6.2-1.2L31.3 6c-1.8 0-3.5.1-5.3.4l-.2 6.3c-2 .5-4 1.2-6 2.1l-4-4.8c-1.6.9-3 1.9-4.4 3l3 5.6c-1.6 1.3-3 3-4.1 4.7l-6-2A32.5 32.5 0 0 0 2 26l5.4 3.3c-.7 2-1 4.2-1.2 6.2L0 36.7c0 1.8.1 3.5.4 5.3l6.3.2c.5 2 1.2 4 2.1 6l-4.8 4c.9 1.6 1.9 3 3 4.4l5.6-3c1.4 1.6 3 3 4.7 4.1l-2 6A30.5 30.5 0 0 0 20 66l3.4-5.4c2 .7 4 1 6.1 1.2l1.2 6.2c1.8 0 3.5-.1 5.3-.4l.2-6.3c2-.5 4-1.2 6-2.1l4 4.8c1.6-.9 3-1.9 4.4-3l-3-5.6c1.6-1.3 3-3 4.1-4.7l6 2A32 32 0 0 0 60 48l-5.4-3.3c.7-2 1-4.2 1.2-6.2zm-13.5 4a12.5 12.5 0 1 1-22.6-11 12.5 12.5 0 0 1 22.6 11z"
            />
            <animateTransform
              attributeName="transform"
              begin="0s"
              dur="3s"
              from="0 31 37"
              repeatCount="indefinite"
              to="360 31 37"
              type="rotate"
            />
          </g>
          <g id="small">
            <path
              fill="none"
              stroke="#7500ac"
              d="M93 19.3l6-3c-.4-1.6-1-3.2-1.7-4.8L90.8 13c-.9-1.4-2-2.7-3.4-3.8l2.1-6.3A21.8 21.8 0 0 0 85 .7l-3.6 5.5c-1.7-.4-3.4-.5-5.1-.3l-3-5.9c-1.6.4-3.2 1-4.7 1.7L70 8c-1.5 1-2.8 2-3.9 3.5L60 9.4a20.6 20.6 0 0 0-2.2 4.6l5.5 3.6a15 15 0 0 0-.3 5.1l-5.9 3c.4 1.6 1 3.2 1.7 4.7L65 29c1 1.5 2.1 2.8 3.5 3.9l-2.1 6.3a21 21 0 0 0 4.5 2.2l3.6-5.6c1.7.4 3.5.5 5.2.3l2.9 5.9c1.6-.4 3.2-1 4.8-1.7L86 34c1.4-1 2.7-2.1 3.8-3.5l6.3 2.1a21.5 21.5 0 0 0 2.2-4.5l-5.6-3.6c.4-1.7.5-3.5.3-5.1zM84.5 24a7 7 0 1 1-12.8-6.2 7 7 0 0 1 12.8 6.2z"
            />
            <animateTransform
              attributeName="transform"
              begin="0s"
              dur="2s"
              from="0 78 21"
              repeatCount="indefinite"
              to="-360 78 21"
              type="rotate"
            />
          </g>
        </svg>
        <h1>¡Estamos reestructurando nuestra web!</h1>
        <h2>Enfocados en tu impacto digital</h2>
        <br />
        <h3>
          Estimado usuario, estamos reestructurando nuestro sitio web, los
          próximos cambios serán increíbles; sabemos que este 2025 estará lleno
          de sorpresas. Si nos necesitas por ahora, aquí encontrarás nuestra
          información de contacto.
        </h3>
        <br />
        <a
          class="building-site__contact"
          href="https://api.whatsapp.com/send?phone=573137840166"
          target="_blank"
          >Contácto: 313-784-0166</a
        >
        <br />
        <h4>
          #EmpresaLlanera #DejanosSerParteDeTuEvolución
          #EnfocadosEnTuImpactoDigital
        </h4>
      </main>
    </div>
  </div>
</template>

<script>
import logo from "../assets/logo.svg";

export default {
  name: "HelloWorld",

  data: () => ({
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
    importantLinks: [
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com",
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuejs.com/vuetify",
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs",
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify",
      },
    ],
    logo,
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com",
      },
      {
        text: "Roadmap",
        href: "https://vuetifyjs.com/introduction/roadmap/",
      },
      {
        text: "Frequently Asked Questions",
        href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
      },
    ],
  }),
};
</script>

<style scoped>
.building-site {
  background: var(--white-secondary);
  font-family: "Poppins", sans-serif !important;
  margin: auto;
  padding: 50px 0;
}

/* html,
body {
  display: grid;
  align-items: center;
  font-family: "Roboto", sans-serif;
  height: 100%;
  background: #eeeef4;
  font-weight: 100;
  user-select: none;
} */

main {
  display: flex;
  margin: 0 20px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

main h1 {
  font-size: 3em;
  font-weight: bolder;
  color: var(--primary);
  margin: 0;
}

main h3 {
  max-width: 800px;
  font-size: 1.5em;
  font-weight: 300;
  margin-bottom: 0;
}

main h2 {
  font-size: 1.5em;
  /* font-weight: 100; */
  margin-top: 0;
}

main a {
  font-size: 1.5em;
  /* font-weight: 300; */
  color: var(--primary);
  text-decoration: none;
}
.building-site__contact {
  font-weight: 800;
  /* color: ; */
}
@media screen and (max-width: 480px) {
  main h1 {
    font-size: 2.5em;
  }
  .building-site {
    padding: 50px 10px;
  }
}
@media screen and (max-width: 360px) {
  main h1 {
    font-size: 2em;
  }
}
</style>
