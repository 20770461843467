<template>
  <div>
    <section class="steps">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 150"
        class="svg-waves white-wave"
      >
        <path
          fill="#ffffff"
          fill-opacity="1"
          d="M0,64L48,80C96,96,192,128,288,122.7C384,117,480,75,576,80C672,85,768,139,864,138.7C960,139,1056,85,1152,74.7C1248,64,1344,96,1392,112L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 150"
        class="svg-waves-2 white-wave"
      >
        <path
          fill="#fafafa"
          fill-opacity="1"
          d="M0,64L48,80C96,96,192,128,288,122.7C384,117,480,75,576,80C672,85,768,139,864,138.7C960,139,1056,85,1152,74.7C1248,64,1344,96,1392,112L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
      <div class="steps-steps">
        <div class="steps__container">
          <h2 class="steps__h2">Tu página web en 4 pasos</h2>
          <div class="steps__steps">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide>
                <div
                  class="steps__step steps__step--no-margin-left"
                  :class="step1 == true ? 'steps__step-selected' : ''"
                  @click="stepOne"
                >
                  <h3>1. Recolección de la información</h3>
                  <p>
                    Recolectamos la información necesaria para moldear tu
                    proyecto y que sea mejor de lo que esperas.
                  </p>
                  <div class="steps__step-image">
                    <img
                      src="/statics/img/steps/step1.svg"
                      alt="Recolección de la información"
                    />
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div
                  class="steps__step"
                  :class="step2 == true ? 'steps__step-selected' : ''"
                  @click="stepTwo"
                >
                  <h3>2. Análisis y Diseño</h3>
                  <p>
                    Recolectamos la información necesaria para moldear tu
                    proyecto y que sea mejor de lo que esperas.
                  </p>
                  <div class="steps__step-image">
                    <img
                      src="/statics/img/steps/step2.svg"
                      alt="Recolección de la información"
                    />
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div
                  class="steps__step"
                  :class="step3 == true ? 'steps__step-selected' : ''"
                  @click="stepThree"
                >
                  <h3>3. Desarrollo del proyecto</h3>
                  <p>
                    Recolectamos la información necesaria para moldear tu
                    proyecto y que sea mejor de lo que esperas.
                  </p>
                  <div class="steps__step-image">
                    <img
                      src="/statics/img/steps/step3.svg"
                      alt="Recolección de la información"
                    />
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div
                  class="steps__step steps__step--no-margin-right"
                  :class="step5 == true ? 'steps__step-selected' : ''"
                  @click="stepFive"
                >
                  <h3>4. Aprobación y entrega</h3>
                  <p>
                    Recolectamos la información necesaria para moldear tu
                    proyecto y que sea mejor de lo que esperas.
                  </p>
                  <div class="steps__step-image">
                    <img
                      src="/statics/img/steps/step5.svg"
                      alt="Recolección de la información"
                    />
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Steps",
  title: "desarrollamos tu sitio web en 5 pasos.",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 20,
        pagination: {
          // el: ".swiper-pagination-clients",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 25,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 25,
          },
        },
      },
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
    };
  },
  methods: {
    stepOne() {
      this.step1 = true;
      this.step2 = this.step3 = this.step4 = this.step5 = false;
    },
    stepTwo() {
      this.step2 = true;
      this.step1 = this.step3 = this.step4 = this.step5 = false;
    },
    stepThree() {
      this.step3 = true;
      this.step1 = this.step2 = this.step4 = this.step5 = false;
    },
    stepFour() {
      this.step4 = true;
      this.step1 = this.step2 = this.step3 = this.step5 = false;
    },
    stepFive() {
      this.step5 = true;
      this.step1 = this.step2 = this.step3 = this.step4 = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  width: 100%;

  .swiper-slide {
    height: 320px;
    text-align: center;
    margin-bottom: 20px;
  }
  .swiper-wrapper {
    box-sizing: inherit !important;
  }
}
.steps {
  position: relative;
  background-image: url(/statics/img/shapes/shape-back.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.steps-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: var(--white-secondary); */
  position: relative;
}
.svg-waves {
  position: absolute;
  transform: rotate(180deg);
  /* margin: -2px; */
}
.svg-waves-2 {
  transform: scaleX(-1);
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -2px;
}
.steps__shape-back {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.steps__h2 {
  margin-bottom: 20px;
  color: var(--white);
  font-size: 35px;
  font-weight: 900;
  text-align: center;
  position: relative;
  z-index: 3;
}
.steps__container {
  margin: auto;
  padding: 100px 0;
  max-width: 1200px;
  z-index: 1;
}
.step__content {
  margin: 10px auto;
  border: 1px solid var(--primary);
}
.step__content h3 {
  text-transform: uppercase;
  margin-top: 20px;
  color: var(--white);
}
.step__content p {
  color: var(--white);
  margin: 20px;
  text-align: justify;
  font-size: 17px;
  font-weight: 500;
}
.steps__steps {
  display: grid;
  width: 100%;
}
.steps__step {
  height: 100%;
  display: grid;
  align-items: center;
  padding: 5px 10px;
  background: var(--white-secondary);
  border-radius: 10px;
  -webkit-box-shadow: 3px 6px 4px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 6px 4px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 6px 4px -7px rgba(0, 0, 0, 0.75);
}
.steps__step:hover {
  background: var(--secondary);
  cursor: pointer;
}
.steps__step-selected {
  background: var(--secondary);
}
.steps__step-image img {
  /* border-radius: 10px; */
  width: 70%;
}
.steps__step h3 {
  color: var(--primary);
  font-size: 22px;
  font-weight: 900;
}
.steps__step p {
  color: var(--black);
  font-weight: 500;
  margin-bottom: 0 !important;
}
@media screen and (min-width: 1500px) {
  .swiper {
    /* width: 85%; */
    max-width: 1200px !important;
  }
  .step__content {
    width: 85%;
  }
}

@media screen and (max-width: 1199px) {
  .steps__container {
    margin: 0 100px;
  }
}

@media screen and (max-width: 991px) {
  .steps {
    background-size: cover;
  }
}
@media screen and (max-width: 767px) {
  .steps {
    margin: -1px;
  }
  .steps__container {
    margin: 0 20px;
  }
  /* .step__content {
    display: grid;
    grid-template-columns: 100%;
  } */
  .step__content p {
    margin: 20px 20px 0;
  }
}
</style>
