<template>
  <div class="scheduling">
    <div class="scheduling__shapes">
      <img
        class="scheduling__shape1"
        src="statics/img/shapes/shape1.png"
        alt=""
      />
      <img
        class="scheduling__shape2"
        src="statics/img/shapes/shape1.png"
        alt=""
      />
      <div class="scheduling__content">
        <div class="scheduling__text">
          <h2 class="scheduling__content-h1">ASESORÍA PERSONALIZADA</h2>
          <h2 class="scheduling__content-h2">SOLO CON UN <span> Clic</span></h2>
          <p class="scheduling__content-p">
            Agenda una video-conferencia, <br />
            nuestro equipo esta listo para escucharte.
          </p>
          <!-- <hr class="scheduling__content-hr" /> -->
          <div>
            <a
              class="scheduling__content-link"
              href="https://calendly.com/appearssas/asesoria-personalizada"
              target="_blank"
            >
              <v-btn class="scheduling__content-btn"> AGENDAR </v-btn>
            </a>
            <img
              class="scheduling__cursor fadeInLeft"
              v-wow="{
                'animation-name': 'fadeInLeft',
                'animation-duration': '1.6s',
              }"
              src="statics/img/shapes/cursor.png"
              alt=""
            />
          </div>
        </div>
        <div>
          <img
            class="scheduling__img fadeInRight"
            v-wow="{
              'animation-name': 'fadeInRight',
              'animation-duration': '1.6s',
            }"
            src="statics/img/shapes/schedule.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Scheduling",
  setup() {},
};
</script>

<style scoped>
.scheduling {
  /* background: var(--white); */
  background: var(--white-secondary);
  width: 100%;
  /* position: relative; */
}
.scheduling__shapes {
  justify-content: center;
  display: flex;
  padding: 100px 0;
  /* height: 500px; */
}
.scheduling__shape1 {
  display: none;
  align-self: center;
  position: absolute;
  left: 0;
}
.scheduling__shape2 {
  position: absolute;
  display: none;
  align-self: center;
  right: 0;
  transform: rotate(180deg);
}
.scheduling__content {
  display: grid;
  width: 80%;
  max-width: 1200px;
  grid-template-columns: 60% 40%;
  align-items: center;
}
.scheduling__text {
  /* display: flex;
  align-items: center; */
}
.scheduling__img {
  width: 100%;
  -webkit-box-shadow: 3px 6px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 6px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 6px 11px -6px rgba(0, 0, 0, 0.75);
}
.scheduling__content-h1 {
  color: var(--secondary);
  font-size: 40px;
  font-weight: 900;
  font-style: italic;
  line-height: 0.8;
}
.scheduling__content-h2 {
  /* text-align: center; */
  margin-right: 50px;
  justify-content: center;
  position: relative;
  font-size: 25px;
  font-weight: 900;
  font-style: italic;
}
.scheduling__content-h2 span {
  position: absolute;
  top: -20px;
  font-size: 45px;
  font-family: "Kaushan Script", cursive;
}
.scheduling__content-p {
  font-size: 25px;
  font-weight: 600;
  line-height: 1.2;
}
.scheduling__content-hr {
  margin: 5px;
  background: var(--primary);
  height: 3px;
  border: none;
}
.scheduling__content-link {
  text-decoration: none;
}
.scheduling__content-btn {
  background: var(--secondary) !important;
  width: 200px;
  margin-top: 5px;
  border-radius: 0;
  color: var(--white);
  font-size: 25px;
  font-weight: 900;
  font-style: italic;
  -webkit-box-shadow: 3px 6px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 6px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 6px 11px -6px rgba(0, 0, 0, 0.75);
}
.scheduling__cursor {
  position: absolute;
  transform: rotate(310deg);
  height: 100px;
}
@media screen and (max-width: 650px) {
  .scheduling__content {
    grid-template-columns: 100%;
    text-align: center;
  }
  .scheduling__img {
    margin-top: 50px;
  }
  .scheduling__shapes {
    padding: 50px 0;
  }
  .scheduling__shape1,
  .scheduling__shape2 {
    height: 100px;
  }
}
@media screen and (max-width: 480px) {
  .scheduling {
    padding: 50px 0;
  }
  .scheduling__content-p {
    margin: 0 30px;
  }
}
@media screen and (max-width: 420px) {
  .scheduling__shapes {
    position: relative;
  }
  .scheduling__shape1 {
    display: none;
    top: 75px;
  }
  .scheduling__shape2 {
    display: none;
    top: 75px;
  }
  .scheduling__cursor {
    transform: rotate(280deg);
    height: 50px;
  }
}
@media screen and (max-width: 365px) {
  .scheduling__content-h1 {
    font-size: 35px;
  }
}
</style>
