import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Vuex from "vuex";

import "vue2-animate/dist/vue2-animate.min.css";

Vue.use(Vuex);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/quienes-somos",
    name: "Quienes somos",
    component: () => import("../views/AboutUs.vue"),
  },
  {
    path: "/servicios",
    name: "Servicios",
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/planes",
    name: "Planes",
    component: () => import("../views/Plans.vue"),
  },
  {
    path: "/proyectos",
    name: "Proyectos",
    component: () => import("../views/Projects.vue"),
  },
  {
    path: "/politica-privacidad",
    name: "Política de privacidad",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/politica-cookies",
    name: "Política de cookies",
    component: () => import("../views/CookiesPolicy.vue"),
  },
  {
    path: "/garantias",
    name: "Garantías",
    component: () => import("../views/Guarantee.vue"),
  },
  {
    path: "/employee/:id",
    name: "Colaborador",
    props: true,
    component: () => import("../views/Collaborator.vue"),
  },
  {
    path: "/paginas-web-villavicencio",
    name: "Paginas web Villavicencio",
    component: () => import("../views/WebSitesVillavicencio.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    component: () => import("../views/Error404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
