<template>
  <section class="hero">
    <swiper class="swiper" :options="swiperOptionHero">
      <swiper-slide>
        <div class="hero__box">
          <div class="hero__left">
            <h1 class="hero__left-h1">Innovación</h1>
            <h2 class="hero__left-h2">
              Impactando el transporte <br />
              en la región con <span>Qinspecting</span>
            </h2>
            <p class="hero__left-p">
              Qinpecting es la plataforma de administración de flotas, que
              mediante <b>checklist electrónicos</b>, proporciona ganancia de
              tiempo, reducción de costos operacionales, trazabilidad y mayor
              calidad en la gestión de la información, dando más seguridad a las
              operaciones en las empresas de transporte.
            </p>
          </div>
          <div
            class="hero__right fadeInUp"
            v-wow="{
              'animation-name': 'fadeInUp',
              'animation-duration': '1.6s',
            }"
          >
            <div
              class="hero__right-video-box"
              style="height: 325px"
              itemscope
              itemtype="https://schema.org/VideoGallery"
            >
              <img src="statics/img/logo-w.png" alt="Logo de Appears blanco" />
              <iframe
                itemprop="video"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/e_2CVw0WVDM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </swiper-slide>
      <!-- <swiper-slide>
        <div class="hero__box">
          <div class="hero__left">
            <h1 class="hero__left-h1">Servicio</h1>
            <h2 class="hero__left-h2">Enfocados en tu impacto digital</h2>
            <p class="hero__left-p">
              Somos una <b>Agencia Digital</b> preparada para ayudarte a dar el
              salto tecnológico, tenemos todo un equipo esperándote para
              desarrollar tus ideas con el objetivo de llevarlas a un nivel
              superior, descubre el impacto de la tecnología en esta era
              digital.
            </p>
            <div class="hero__left-services">
              <v-tooltip bottom color="purple" class="hero__left-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    src="statics/img/services/sites.png"
                    alt="Icono de sitios web"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>Sitios web</span>
              </v-tooltip>
              <v-tooltip bottom color="purple" class="hero__left-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    src="statics/img/services/dev.png"
                    alt="Icono de desarrollo a la medida"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>Desarrollo a la medida</span>
              </v-tooltip>
              <v-tooltip bottom color="purple" class="hero__left-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    src="statics/img/services/social.png"
                    alt="Icono de social marketing"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>SEM (Search Engine Marketing)</span>
              </v-tooltip>
              <v-tooltip bottom color="purple" class="hero__left-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    src="statics/img/services/apps.png"
                    alt="Icono de desarrollo de apps"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>Apps</span>
              </v-tooltip>
              <v-tooltip bottom color="purple" class="hero__left-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    src="statics/img/services/ss.png"
                    alt="Icono de SEO (Search Engine Optimization)"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>SEO (Search Engine Optimization)</span>
              </v-tooltip>
              <v-tooltip bottom color="purple" class="hero__left-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    src="statics/img/services/values.png"
                    alt="Icono de trabajo en equipo"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>Trabajo en equipo</span>
              </v-tooltip>
              <v-tooltip bottom color="purple" class="hero__left-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    src="statics/img/services/support.png"
                    alt="Icono de soporte"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>Soporte</span>
              </v-tooltip>
              <v-tooltip bottom color="purple" class="hero__left-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    src="statics/img/services/services.png"
                    alt="Icono de servicio al cliente"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>Servicio al cliente</span>
              </v-tooltip>
            </div>
          </div>
          <div
            class="hero__right fadeInUp"
            v-wow="{
              'animation-name': 'fadeInUp',
              'animation-duration': '1.6s',
            }"
          >
            <div
              class="hero__right-video-box"
              style="height: 325px"
              itemscope
              itemtype="https://schema.org/VideoGallery"
            >
              <img src="statics/img/logo-w.png" alt="Logo de Appears blanco" />
              <iframe
                itemprop="video"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/JKIHu7UPiV0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </swiper-slide> -->
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "swiper-example-vertical",
  title: "Vertical slider",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptionHero: {
        direction: "vertical",
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper-container-vertical > .swiper-pagination-bullets {
  right: 30px;
}
/* .swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
  background: var(--primary) !important;
} */
/*  */
.hero {
  width: 100%;
  height: 500px;

  /* padding-bottom: 50px; */

  .swiper {
    height: 100%;
    width: 100%;

    .swiper-slide {
      /* height: 500px; */
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: bold;
      font-size: 2em;
      background-color: var(--white-secondary);
    }
  }
}
.hero__box {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1200px;
  width: 100%;
  margin: 80px;
}
.hero__left {
  text-align: left;
  /* background: blue; */
}
.hero__left-h1 {
  /* font-size: 40px; */
  color: var(--primary-opt);
  font-weight: 800;
  line-height: 0;
}
.hero__left-h2 {
  color: var(--black);
  font-size: 30px;
  font-weight: 800;
  line-height: 1;
}
.hero__left-h2 span {
  color: var(--positive);
  font-size: 35px;
  font-weight: 800;
  line-height: 1;
}
.hero__left-p {
  margin-top: 20px;
  margin-right: 20px;
  color: var(--black);
  font-size: 20px;
  font-weight: normal;
}
.hero__left-services img {
  height: 50px;
  margin-right: 5px;
  border: 2.5px solid var(--primary);
  border-radius: 15px;
}
.hero__left-tooltip {
  background: var(--primary) !important;
}
.hero__right-video-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  background: var(--secondary);
  border: 5px solid var(--primary);
  border-radius: 20px;
}
.hero__right-video-box img {
  height: 200px;
  position: absolute;
}
.hero__right-video-box iframe {
  border-radius: 20px;
  padding: 5px;
  z-index: 3;
}
@media screen and (max-width: 991px) {
  .hero {
    width: 100%;
    height: 900px;
  }
  .hero__box {
    grid-template-columns: 100%;
    margin: 30px 30px 60px 30px;
  }
}
@media screen and (max-width: 480px) {
  .hero__left-h1 {
    font-size: 35px;
    line-height: 0.5;
  }
  .hero__left-h2,
  .hero__left-h2 span {
    font-size: 22px;
  }
  .hero__right-video-box {
    height: 200px;
  }
}
</style>
