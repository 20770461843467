<template>
  <section class="team">
    <v-container>
      <v-row class="team__title">
        <span class="team__span">#TeamAppears</span>
        <h2 class="team__h2">Nuestro Equipo</h2>
        <span class="team__span"
          >Trabajando desde cualquier lugar del pais. 💪</span
        >
      </v-row>
      <v-row class="team__box">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide>
            <div class="team__item">
              <img
                class="team__item-img"
                src="../../public/statics/img/team/lea.svg"
                alt="Foto de Leandro Baquero"
              />
              <h3 class="team__item-h3">LEANDRO BAQUERO</h3>
              <h4 class="team__item-h4">CEO & General Manager</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team__item">
              <img
                class="team__item-img"
                src="../../public/statics/img/team/cr7.svg"
                alt="Foto de Cristian Mendoza"
              />
              <h3 class="team__item-h3">CRISTIAN MENDOZA</h3>
              <h4 class="team__item-h4">Project Manager</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team__item">
              <img
                class="team__item-img"
                src="../../public/statics/img/team/elv.svg"
                alt="Foto de Elver Rodriguez"
              />
              <h3 class="team__item-h3">ELVER RODRIGUEZ</h3>
              <h4 class="team__item-h4">Project Manager</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team__item">
              <img
                class="team__item-img"
                src="../../public/statics/img/team/osc.svg"
                alt="Foto de Oscar Santamaria"
              />
              <h3 class="team__item-h3">OSCAR SANTAMARIA</h3>
              <h4 class="team__item-h4">Project Manager</h4>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="team__item">
              <img
                class="team__item-img"
                src="../../public/statics/img/team/oma.svg"
                alt="Foto de Omar Yesid Duran"
              />
              <h3 class="team__item-h3">OMAR YESID DURAN</h3>
              <h4 class="team__item-h4">Advisor</h4>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Team",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 10,
        pagination: {
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1024: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  width: 100%;
}
.team {
  margin: auto;
  max-width: 1200px;
  padding: 70px 0 70px 0;
  text-align: center;
  background: var(--white);
}
.team__title {
  display: block;
}
.team__span {
  font-size: 35px;
  font-weight: 900;
}
.team__h2 {
  color: var(--primary);
  font-weight: 900;
  font-size: 45px;
  line-height: 0.8;
}
.team__box {
  margin-top: 60px;
  /* max-width: 1200px; */
}
.team__item-img {
  width: 100%;
  max-width: 150px;
}
.team__item-h3 {
  margin-top: 10px;
  font-weight: 900;
}
.team__item-h4 {
  color: var(--primary) !important;
  font-weight: 900;
}

/* @media screen and (min-width: 1500px) {
  .swiper {
    width: 95%;
  }
} */

@media screen and (max-width: 410px) {
  .team__h2 {
    font-size: 35px;
  }
  .team__item-h4 {
    margin-bottom: 20px;
  }
}
</style>
