<template>
  <div itemscope itemtype="http://schema.org/Organization">
    <v-app-bar class="header">
      <v-toolbar-title class="header__title">
        <router-link itemprop="url" to="/">
          <img
            class="header__logo"
            src="/statics/img/logo.png"
            alt="Logo de appears, empresa de desarrollo de sotfware"
          />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title
        itemscope
        itemtype="http://schema.org/SiteNavigationElement"
        class="menu"
      >
        <router-link itemprop="url" class="menu__item" to="/#">
          <span
            itemprop="name"
            :class="
              this.$route.path == '/quienes-somos' ? 'menu__item-chip' : ''
            "
            >¿Quiénes somos?</span
          >
        </router-link>
        <router-link itemprop="url" class="menu__item" to="/#servicios">
          <span
            itemprop="name"
            :class="this.$route.path == '/servicios' ? 'menu__item-chip' : ''"
            >Servicios</span
          >
        </router-link>
        <router-link itemprop="url" class="menu__item" to="/#proyectos">
          <span
            itemprop="name"
            :class="this.$route.path == '/proyectos' ? 'menu__item-chip' : ''"
            >Proyectos</span
          >
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="get-started">
        <a
          href="https://calendly.com/appearssas/asesoria-personalizada"
          target="_blank"
        >
          <v-btn class="btn__get-started"> Empecemos! </v-btn>
        </a>
      </div>
      <v-app-bar-nav-icon
        class="btn_menu"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer class="drawer" v-model="drawer" absolute temporary>
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="statics/img/logo-r.png"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>#EmpresaLlanera</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item>
            <v-list-item-title itemprop="name">
              <router-link itemprop="url" class="menu__item" to="/#"
                >¿Quiénes somos?
              </router-link></v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title itemprop="name"
              ><router-link itemprop="url" class="menu__item" to="/#servicios"
                >Servicios</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title itemprop="name"
              ><router-link itemprop="url" class="menu__item" to="/#proyectos"
                >Proyectos</router-link
              ></v-list-item-title
            >
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-title itemprop="name">
              <a
                itemprop="url"
                class="menu__item"
                href="https://calendly.com/appearssas/asesoria-personalizada"
                target="_blank"
              >
                <v-btn class="btn__get-started"> Empecemos! </v-btn></a
              >
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      items: [
        { title: "Home", icon: "mdi-view-dashboard" },
        { title: "About", icon: "mdi-forum" },
      ],
    };
  },
};
</script>
<style scoped>
.header {
  position: static;
  position: relative;
  z-index: 2;
  background: var(--white) !important;
  height: 100px !important;
  padding: 20px 70px;
  align-content: center;
}
.header__logo {
  display: grid;
  align-self: center;
  height: 70px;
}
.get-started a {
  text-decoration: none;
}
.btn__get-started {
  background: var(--primary) !important;
  color: var(--white-secondary) !important;
  border-radius: 20px;
  text-transform: capitalize;
  font-size: 17px !important;
  font-weight: bolder;
}
.bg-purple {
  background: var(--primary) !important;
}
.btn_menu {
  display: none;
}
.menu {
  display: contents;
}
.menu__item {
  margin: 0 20px;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
  color: var(--black);
}
.menu__item-chip {
  padding: 3px 10px;
  border-radius: 20px;
  cursor: pointer;
  background: var(--secondary) !important;
  font-weight: 600;
  font-size: 17px !important;
  -webkit-box-shadow: 1px 5px 8px -2px #ab67d1;
  -moz-box-shadow: 1px 5px 8px -2px #ab67d1;
  box-shadow: 1px 5px 8px -2px #ab67d1;
}
.drawer {
  justify-content: center;
  padding-top: 10px;
}
@media screen and (min-width: 1400px) {
  .header {
    padding: 20px 10%;
  }
}
@media screen and (min-width: 1500px) {
  .header {
    padding: 20px 15%;
  }
}
@media screen and (max-width: 991px) {
  .header {
    padding: 20px 20px;
  }
  .menu,
  .get-started {
    display: none;
  }
  .menu__item-chip {
    border: 0.2px solid;
    box-shadow: none;
  }
  .btn__get-started {
    margin-top: 10px;
    font-size: 17px;
  }
  .btn_menu {
    display: block;
    float: right;
  }
}
</style>
